import { HeroSection, ContentSectionData } from 'types/domain/content';

// Main homepage hero content
export const heroContent: HeroSection = {
  title:
    'distriqt is a <b>digital consultancy</b>, providing development and technology services for modern businesses.',
};

// Main intro text section
export const introContent: ContentSectionData = {
  title: 'About us',
  items: [
    {
      title: '',
      paragraphs: [
        'Starting out as friends in Queensland over 25 years ago, Michael and Shane have been working together and operating distriqt since 2008. With a shared passion for computers and software, we joined forces to form an excellent team, and have been providing software development services for hundreds of clients for over 13 years.',
      ],
    },
  ],
};
